import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {FaTrashAlt, FaPen } from 'react-icons/fa'; // Importing necessary icons
import Filter from './Filter';

const Gallery = () => {
  const [photos, setPhotos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const token = localStorage.getItem('token');

  useEffect(() => {
    axios.get('https://ituzebe.onrender.com/api/photos', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(res => {
        setPhotos(res.data);
        const uniqueCategories = [...new Set(res.data.map(photo => photo.category))];
        setCategories(uniqueCategories);
      })
      .catch(err => console.error(err));
  }, [token]);

  const handleCategoryChange = category => {
    setSelectedCategory(category);
  };

  const handleCreate = async newPhotoData => {
    try {
      const res = await axios.post('https://ituzebe.onrender.com/api/photos', newPhotoData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPhotos([...photos, res.data]);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async id => {
    try {
      await axios.delete(`https://ituzebe.onrender.com/api/photos/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPhotos(photos.filter(photo => photo._id !== id));
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdate = async (id, updatedData) => {
    try {
      const res = await axios.put(`https://ituzebe.onrender.com/api/photos/${id}`, updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPhotos(photos.map(photo => (photo._id === id ? res.data : photo)));
    } catch (err) {
      console.error(err);
    }
  };

  const filteredPhotos = selectedCategory
    ? photos.filter(photo => photo.category === selectedCategory)
    : photos;

 

 
  return (
    <div className="min-h-screen bg-stone-900 text-white flex flex-col font-Arial">
   

      {/* Main content */}
      <div className="container mx-auto px-4 py-8 bg-white">
        <div className="mb-8 text-black">
          <h1 className="text-4xl font-semibold mb-4 text-center">Explore Our Gallery</h1>
          <Filter
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
        </div>

        {/* Add New Photo form */}
        <form
          onSubmit={e => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const newPhotoData = {
              title: formData.get('title'),
              category: formData.get('category'),
              imageUrl: formData.get('imageUrl'),
              description: formData.get('description'),
            };
            handleCreate(newPhotoData);
            e.target.reset();
          }}
          className="mb-8 bg-gray-100 p-4 rounded-lg shadow-md"
        >
          <h2 className="text-3xl font-semibold mb-4 text-center">Add New Photo</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <input
              type="text"
              name="title"
              placeholder="Title"
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
              required
            />
            <input
              type="text"
              name="category"
              placeholder="Category"
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
              required
            />
            <input
              type="url"
              name="imageUrl"
              placeholder="Image URL"
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
              required
            />
            <textarea
              name="description"
              placeholder="Description"
              rows="3"
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
            ></textarea>
          </div>
          <button type="submit" className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none">
            Add Photo
          </button>
        </form>

        {/* Display Photos */}
        {filteredPhotos.length > 0 ? (
          <div>
            <h2 className="text-3xl font-semibold mb-4 text-center">{selectedCategory ? `${selectedCategory} Photos` : 'All Photos'}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {filteredPhotos.map(photo => (
                <div key={photo._id} className="bg-white rounded-lg overflow-hidden shadow-lg">
                  <img src={photo.imageUrl} alt={photo.title} className="w-full h-64 object-cover" />
                  <div className="p-4">
                    <h3 className="text-lg font-semibold">{photo.title}</h3>
                    <p className="text-gray-600">{photo.description}</p>
                    <p className="text-sm text-gray-500 mt-2">{photo.category}</p>
                    <div className="flex justify-end mt-4 space-x-4">
                      <button
                        onClick={() => handleDelete(photo._id)}
                        className="text-red-500 hover:text-red-700 flex items-center focus:outline-none"
                        title="Delete Photo"
                      >
                        <FaTrashAlt className="mr-2" /> Delete
                      </button>
                      <button
                        onClick={() => {
                          const updatedData = prompt('Enter updated title:');
                          if (updatedData) {
                            handleUpdate(photo._id, { title: updatedData });
                          }
                        }}
                        className="text-blue-500 hover:text-blue-700 flex items-center focus:outline-none"
                        title="Update Title"
                      >
                        <FaPen className="mr-2" /> Update
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-xl">No photos found.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
